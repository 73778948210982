export const projectsEn = [
    {
        id:"ids",
        name: "Bulk Disbursement Solution",
        technologies: 'NodeJS, Express, Java, Spring Boot, Nginx, Redis, RabbitMQ, Angular, Bootstrap, MySQL, Docker, Kubernetes, AWS',
        images: [
            {
                title:'ids',
                src: "assets/images/portfolio.png",
            },
            {
                title:'ids',
                src:"assets/images/voyage.webp",
            },
            {
                title:'Housing',
                src: "assets/images/logements.webp",
            },
        ],
        url: "https://play.google.com/store/apps/details?id=team.traveler.teamtraveler",
        detail:"An integrated microservices-based solution which transforms bulk financial disbursement process from manual to automated, increasing visibility and speed. Includes complicated share calculation and financial rules for cash-out share. As Principal Architect, developed core payment processing microservice and share distribution calculation.",
        codeSourceUrl:'https://github.com/zinedineBenkhider/team_traveler',
        detailIsDisplayed: false,
    },
    {
        id:"ecp",
        name: "Everest Portal",
        technologies: 'C#, Web API, Entity Framework, Angular, MS SQL',
        images: [
            {
                title:'Marketing campaigns',
                src:   "assets/images/portfolio.png",
            },
            {
                title:'Campaign detail',
                src :"assets/images/compagne-detail.jpg",
            },
            {
                title:"Email performance",
                src: "assets/images/email-detail.jpg",
            },
            {
                title:"Performance of an asset",
                src: "assets/images/asset-detail.jpg" 
            },
            
        ],
        url:'#portfolio',
        detail:"A market definition designer and territory structure builder through drag-and-drop UX which applies prescribed dataset and transforms into business rules. Built legacy data transfer framework and versioning system with fast retrieval and reverting. Automation process handles daily delta operations on change data capture and data refresh with logging. Led geographically diverse team of 10 people to build solution, and personally handled major backend services, drag-and-drop UX with tree structuring, and database framework.",
        codeSourceUrl:'https://github.com/zinedineBenkhider/mautic_monitoring',
        detailIsDisplayed: false,
    },
    {
        id:"dc",
        name: "Dots Connector",
        technologies: 'R Libraries, .NET, MS Sql, Power BI',
        images: [
            {
                title:'Pokedex',
                src:   "assets/images/portfolio.png",
            },
            {
                title:'Trainers',
                src :"assets/images/trainers.png",
            },
            {
                title:"Profile",
                src: "assets/images/profile-trainer.png",
            },            
        ],
        url:'#portfolio',
        detail:"A predictive analytical solution combining clinical, sales, RX, patient, and marketing data, applying Machine Learning algorithms to detect and predict patterns. Led team of 4 people in bridging different data sources and types for an end-to-end view of the drug lifecycle.",
        codeSourceUrl:'https://github.com/ifi-2019/trainer-ui-zinedineBenkhider',
        detailIsDisplayed: false,
    },
    {
        id:"dashboard",
        name: "Brand Performance Dashboard",
        technologies: 'C#, .NET, Java, JavaScript, SQL Server, Analysis Services',
        images: [
            {
                title:'Portfolio',
                src:   "assets/images/portfolio.png",
            },
        ],
        url:'https://medium.com/zinedine-projects/beautiful-cv-portfolio-72921a198640',
        detail:"<p>Brand performance and sales analytics dashboards for numerous pharmaceutical clients all over the globe</p>These consist of data processing, staging, complex ETL, Data warehousing, Cube building, Reporting MDX queries, API for reporting data and front-end responsive visualization with high performance in-memory caching.",
        codeSourceUrl:'https://github.com/zinedineBenkhider/portfolio',
        detailIsDisplayed: false,
    },
    {
        id:"sims",
        name: "Supply Integrity Management",
        technologies: 'C#, .NET, Web API, SQL Server, Angular, Azure',
        images: [
            {
                title:'Riddle',
                src:   "assets/images/portfolio.png",
            },
        ],
        url:'https://transfer-riddle-solver.herokuapp.com/index.html',
        detail:"Automation system for supply integrity management - a generic product for pharmaceutical clients.It included custom distribution rule management and rule suggestions based on previous distributions.",
        codeSourceUrl:'https://github.com/zinedineBenkhider/transfer_riddle_client',
        detailIsDisplayed: false,
    },
    {
      id:"irelease",
      name: "Audit Trail System",
      technologies: 'C#, .NET, Web API, Oracle Apex, Oracle',
      images: [
          {
              title:'Riddle',
              src:   "assets/images/portfolio.png",
          },
      ],
      url:'https://transfer-riddle-solver.herokuapp.com/index.html',
      detail:"It is an audit trail system for a renowned pharmaceutical company of Europe. A separate customized workflow engine was developed from scratch to serve the configuration requirements.",
      codeSourceUrl:'https://github.com/zinedineBenkhider/transfer_riddle_client',
      detailIsDisplayed: false,
  },
];

