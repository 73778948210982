import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  currentYear: number;
  constructor(private _translateService: TranslateService) {
    this._translateService.addLangs(['en', 'fr']);
    this._translateService.setDefaultLang('fr');
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    // (function (d, m) {
    //   var kommunicateSettings = {
    //     appId: '1ea3d40d93c58c78e8ad8f54103c04733',
    //     popupWidget: true,
    //     automaticChatOpenOnNavigation: true,
    //   };
    //   var s = document.createElement('script');
    //   s.type = 'text/javascript';
    //   s.async = true;
    //   s.src = 'https://widget.kommunicate.io/v2/kommunicate.app';
    //   var h = document.getElementsByTagName('head')[0];
    //   h.appendChild(s);
    //   window.kommunicate = m;
    //   m._globals = kommunicateSettings;
    // })(document, window.kommunicate || {});

    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'en');
    }
    let lang: any = localStorage.getItem('lang');
    this._translateService.setDefaultLang(lang);
    this._translateService.use(lang);

    
  }
}
