export const locale = {
    lang: 'en',
    data: {
        'about': {
            'title': 'About',
            'jobTitle': 'Solution Architect, Full-stack Software Engineer',
            'introductionOfMe': 'I am a technology professional with over a decade of experience in architecting, leading, developing, managing, and delivering innovative digital solutions in the Fintech and the Healthcare (pharmaceutical) industry. I am skilled in designing & implementing data-intensive systems, building complex financial middlewares involving state-of-the-art technology and practices, and leading cross-functional diversified teams towards building and deploying enterprise solutions.',
            'passionateAboutDevelopment':'Passionate about development',
            'mobility': '1000 N 4th St. Fairfield, IA 52557',
            'years': 'Years',
            'mobile': '641 233 9168',
            'code': 'experience over 12 years',
            'graduation': "MS in CSE (ongoing)",
            'myResearch': 'Fintech & Pharma',
            'researchDescription': 'System Design & Architecting • Microservices • Fintech, Pharmaceutical & Healthcare Industries • Full Stack Development • Data-Intensive Applications • Research & Development • Leadership • Business Intelligence • Complex Algorithms • Automation • Data Warehousing • System Optimization',
            'and':'and',
            'followMeOn':'Follow me on',
        },
        'contact': {
            'contactMe': 'Contact Me',
            'notHesitateToContactMe': 'Do not hesitate to contact me',
            'identify': 'Identify',
            'enterIdentify': 'Enter your identify',
            'email': 'Email',
            'enterEmail': 'Enter your email',
            'phone': 'Phone',
            'enterPhone': 'Enter your phone no',
            'title': 'Title',
            'enterTitle': 'Enter message title',
            'message': 'Message',
            'send': 'Send',
        },
        'education': {
            'title': 'Curriculum Vitae',
            'eductionTitle': 'Education',
            'experiencesTitle': 'Experiences',
            'centerOfInterest': 'Center of interest',
            'miscellaneous': 'Miscellaneous',
            'sport': 'Sport',
            'hobby': 'Hobby',
            'trips': 'Business Trips',
            'boardGames': 'Board games',
            'languages': 'Languages',
            'mother-tongue': 'Mother Tongue',
            'fluent': 'Fluent',
            'goodLevel': 'Good level',
            'french': 'French',
            'bengali': 'Bengali',
            'english': 'English',
            'masterDegreeTwo': "Master's degree 2 IT",
            'masterDegreeTwoDesc': "Master of degital services",
            'masterDegreeOne': "M.Sc in Computer Science",
            'masterDegreeOneDesc': "Completed on-campus studies and currently taking distance education",
            'bachlorDegree': "B.Sc in Computer Science & Engineering",
            'bachlorDegreeDesc': "Bachlor of Computer Science & Engineering",
            'university2': 'Maharishi International University, IA',
            'university1': 'Bangladesh University of Engineering & Technology',
            'mission':'Responsibility',
            'technologies':'Technologies',
            'detail':'Detail',
            'tripsCountries':'Europe, Australia, India',
            'boardGamesPlayed':'Checkers and dominoes game, Among US, Ludo, ..etc',
            'years':'years'
        },
        'header': {
            'home': 'Home',
            'about': 'About',
            'skills': 'Skills',
            'references': 'References',
            'blog': 'Blog',
            'contactMe': 'Contact me',
        },
        'home': {
            'iAmDeveloper': 'I am ',
        },
        'projects': {
            'title': 'Portfolio',
            'projectDetail':'Project detail',
            'sourceCode':'Source code',
        },
        'references': {
            'title': 'RefereBces',
        },
        'blog': {
          'title': 'Blog',
        },
        'skills': {
            'title': 'Skills',
            'technologies': 'Technologies',
            'tools': 'Tools',
            'services': 'Services',
            'methodologies': 'Methodologies'
        },
    }
}