import { Injectable } from '@angular/core';
import { IMessage } from './models/interfaces';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpClient: HttpClient) { }

  postMessage(message: IMessage): Observable<{ statusCode: number; body: string }> {
    console.log(`inside service: ${message}`);

    return this.httpClient.post<{ statusCode: number; body: string }>(
      environment.API_URL_PERSONAL,
      message
    );
  }
}
