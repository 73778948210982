export const referencesEn = [
    {
        personImg: "assets/images/lex.JPG",
        personFullname: 'AWS Lex',
        personPosition: " Building a chat-bot and integrate in web app",
        personCompany: '',
        message: "Amazon Lex is a web service that allows customers to include conversational interfaces for voice and text in the software applications they are developing. The service, which uses the technology that powers Amazon's virtual assistant Alexa, is part of Amazon's artificial intelligence suite (Amazon AI). Now, developers just need to design conversations according to their requirements in Lex console. The phrases provided by the developer are used to build the natural language model. After publishing the bot, Lex will process the text or voice conversations and execute the code to send responses.",
    },
    // {
    //     personImg: "assets/images/lotfi-benkhider.jpg",
    //     personFullname: 'Benkhider Lotfi',
    //     personPosition: "CEO & Founder",
    //     personCompany: 'Scotfy',
    //     message: "Zinedine completed her internship in my company. He is independent and has a great learning ability. He meets delivery deadlines. He has team spirit. Zinedine does not hesitate to propose new relevant ideas. He respects the instructions, its code is clean.",
    // },
    // {
    //     personImg: "assets/images/mohamed-yahiatene.jpg",
    //     personFullname: 'Yahiatene Mohamed',
    //     personPosition: "DevOps Engineer",
    //     personCompany: 'AG2R La Mondiale',
    //     message: "I worked for two years with Zinedine at the University of Lille. Zinedine is a very good working partner with high knowledge in backend and frontend development, he is always keen to move forward without ever being afraid to use new technologies. He does not hesitate to help his colleagues whenever he can. He manages to solve problems quickly and efficiently with typical optimism.",
    // }
];

