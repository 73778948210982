<section id="cv">
    <div class="container">
        <h2><strong>   {{'education.title' | translate}}</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                <h4><strong>{{'education.eductionTitle' | translate}}</strong></h4>
                <ul class="timeline">
                    <li>
                        <h5>{{'education.masterDegreeOne' | translate}}</h5>
                        <em class="font-italic">{{'education.masterDegreeOneDesc' | translate}} <br> {{'education.university2' | translate}}</em>
                        <p class="year p-2 d-flex justify-content-center">2022 - ongoing</p>
                    </li>
                    <li>
                        <h5>{{'education.bachlorDegree' | translate}}</h5>
                        <em class="font-italic"><br> {{'education.university1' | translate}}</em>
                        <p class="year p-2 d-flex justify-content-center">2007</p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                <h4><strong>{{'education.experiencesTitle' | translate}}</strong></h4>
                <ul class="timeline">
                    <li *ngFor="let experience of experiences;">
                        <h5>{{experience.title}}</h5>
                        <em>{{experience.company}}</em>
                        <div class="d-flex flex-row">
                            <p class="year p-2 d-flex justify-content-center">{{experience.period}}</p>
                            <button (click)="detailOnClick(experience)" type="button"
                                class="btn btn-primary">
                                <span *ngIf="!experience.detailIsDisplayed">+</span><span *ngIf="experience.detailIsDisplayed">-</span> {{'education.detail' | translate}}</button>
                        </div>
                        <p *ngIf="experience.detailIsDisplayed">
                            <span *ngFor="let mission of experience.missions;let index = index">
                                <strong>{{'education.mission' | translate}} {{index+1}}: </strong>{{mission}} <br>
                            </span>
                            <strong>{{'education.technologies' | translate}}:</strong> {{experience.technologies}}
                        </p>
                    </li>

                </ul>

                <!-- <ul class="timeline">
                  <li *ngFor="let experience of experiences;">
                      <h5>{{experience.title}}</h5>
                      <em>{{experience.company}}</em>
                      <div class="d-flex flex-row">
                          <p class="year p-2 d-flex justify-content-center">{{experience.period}}</p>
                          <button (click)="detailOnClick(experience)" type="button" class="btn btn-primary" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                              <span *ngIf="!experience.detailIsDisplayed">+</span><span *ngIf="experience.detailIsDisplayed">-</span> {{'education.detail' | translate}}
                          </button>
                      </div>
                      <div class="collapse" id="collapseExample">
                        <div class="card card-body">
                            <span *ngFor="let mission of experience.missions;let index = index">
                                <strong>{{'education.mission' | translate}} {{index+1}}: </strong>{{mission}} <br>
                            </span>
                            <strong>{{'education.technologies' | translate}}:</strong> {{experience.technologies}}
                        </div>
                      </div>
                  </li> 

              </ul>-->
            </div>
            <div class="col-lg-4 col-md-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                <div class="container row">
                    <div class="aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                        <h4><strong>{{'education.languages' | translate}}</strong></h4>
                        <div class="noProgress langue">
                            <span class=""><img src="assets/images/bd-flag.png" width="20"
                                    alt="mother tongue"><strong> {{'education.bengali' | translate}}</strong> <i class="val">
                                        {{'education.mother-tongue' | translate}}</i></span>
                        </div>
                        <div class="noProgress langue">
                            <span class=""><img src="assets/images/united-states-flag.png" width="20"
                                    alt="international language"><strong> {{'education.english' | translate}}</strong> <i class="val"> {{'education.fluent' | translate}}</i></span>
                        </div>
                    </div>
                </div>
                <div class="container row">
                    <div class="mt-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                        <h4><strong>{{'education.miscellaneous' | translate}}</strong></h4>
                        <div class="noProgress langue">
                          <span>
                            <fa-icon [icon]="planeIcon"></fa-icon><strong> {{'education.trips' | translate}}</strong> <i class="val">
                                {{'education.tripsCountries' | translate}} </i>
                          </span> 
                          
                          
                        </div>

                        <div class="noProgress langue">
                          <span>
                            <fa-icon [icon]="volleyBallIcon"></fa-icon><strong> {{'education.hobby' | translate}}</strong> 
                              <i class="val"> 
                                guitar, travelling, cooking
                              </i>
                        </span>
                        </div>
                        <!-- <div class="noProgress langue">
                            <span>
                                <fa-icon [icon]="gameIcon"></fa-icon><strong> {{'education.boardGames' | translate}} </strong> <i class="val">
                                    {{'education.boardGamesPlayed' | translate}} </i>
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>