export const experiencesEn = [
    {
        title: "Principal Platform Architect, Core Platform",
        company: 'bKash Ltd, BD',
        period: "2021 - 2022",
        technologies: 'NodeJS, Express, Spring Boot, Angular, MySQL, Nginx, RabbitMQ, Redis, Docker, Kubernetes, AWS',
        missions: [
            "Designed and architected complex Fintech solutions on top of core payment platform.",
            "Developed end-to-end microservices architecture and distributed solutions for middleware.",
            "Led payment gateway platform team in optimizing existing payment services.",
            "Designed and built Integrated Disbursement System which transformed weeks of manual work into hours through automation. Included reporting and alert mechanism.",
            "Successfully pitched idea to transform complex pay share calculations on middleware. Implemented solution and received significant client appreciation."
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Consultant, Solution Architecture & Optimization (Part-time)",
        company: 'ASA IT, BD',
        period: "2019 - 2021",
        technologies: 'MS SQL, C#, Angular, Power BI',
        missions: [
            "Architected and optimized in-house system for microfinance operations and performance enhancement.",
            "Built data warehouse on top of financial transaction data and designed executive reporting system.",
            "Optimized complex microfinance application which runs 3,000+ branches with terabytes of data.",
            "Ensured high performance of data warehouse and reporting solution through skilled architecting.",
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Senior Tech Lead",
        company: 'IQVIA, BD (HeadOffice: USA)',
        period: "2016 - 2021",
        technologies: 'C#, ASP .NET Core, Web API, Java, Spring Boot, Angular, MS SQL, MongoDB, Power BI',
        missions: [
            "Led development and management of innovative healthcare solutions, including advanced analytics.",
            "Supported high-profile pharmaceutical clients with optimized services ideas, successfully winning multi-country deals.",
            "Built Proof of Concepts (POC) and demonstrations, traveling globally to Australia, Europe, and India to meet with clients face-to-face.",
            "Developed custom market definition and graphical geo dimension designer for Australian pharmaceutical industry which transformed decades of legacy systems into a single, unified platform with advanced UX and optimized processing.",
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Team Lead, Advanced Data Analytics",
        company: 'IQVIA, BD (HeadOffice: USA)',
        period: "2013 - 2016",
        technologies: 'C#, .NET, Java, SSIS, SSAS, MDX, Power BI',
        missions: [
            "Supervised team in the design and delivery of analytical dashboard solutions.",
            "Optimized legacy processes for global pharmaceutical clients.",
            "Delivered complex business intelligence solutions which handled a large volume of data with efficient reporting systems.",
            "Designed and pipelined full cycle of raw data transformation, staging, aggregation, and client-side optimized solutions.",
        ],
        detailIsDisplayed: false,
    },
    {
      title: "Senior Software Engineer, Full Stack",
      company: 'IQVIA, BD (HeadOffice: USA)',
      period: "2012 - 2013",
      technologies: 'C#, JavaScript, ASP.NET MVC, SSAS, SQL Server',
      missions: [
          "Managed development and implementation of healthcare solutions, clinical trial systems, and analytical services for Europe-based pharmaceutical clients.",
          "Traveled and worked in Europe at Novartis Headquarters to develop a targeted workflow engine for the clinical trial process.",
          "Built and delivered first-ever responsive brand performance dashboard for a renowned Pharma client in UK.",
      ],
      detailIsDisplayed: false,
  },
  {
    title: "Software Engineer, Full Stack",
    company: 'IQVIA, BD (HeadOffice: USA)',
    period: "2010 - 2012",
    technologies: 'C#, JavaScript, ASP.NET MVC, SSAS, SQL Server',
    missions: [
        "Developed business intelligence solutions, staging systems, and reporting systems on sales data with advanced KPIs for pharmaceutical clients worldwide.",
        "Rebuilt system from scratch to align with the latest technologies (Cube and MDX), boosting performance by 80%.",
        "Implemented and integrated modular functionality to enhance existing systems.",
    ],
    detailIsDisplayed: false,
  },
  {
    title: "Software Engineer",
    company: 'eGeneration Ltd. BD',
    period: "2010",
    technologies: 'C#, JavaScript, ASP.NET MVC, MS SQL',
    missions: [
        "Worked within a team to build ERP solution for the garment and clothing industry.",
        "Performed end-to-end implementation of services.",
    ],
    detailIsDisplayed: false,
  },
];

