<section id="contact">
    <div class="container mb-5">
        <h2><strong >{{'contact.contactMe' | translate}}</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row"  data-aos="fade-up"
        data-aos-anchor-placement="top-center">
            <div class="col-md-3 col-lg-3">
                <div class="contact-info">
                    <img src="assets/images/contact-image.png" alt="image" />
                    <h4>{{'contact.notHesitateToContactMe' | translate}}</h4>
                </div>
            </div>
            <div class="col-md-9">
              <form [formGroup]="form" (ngSubmit)="addMessage()">
                <div class="contact-form">
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="identite">{{'contact.identify' | translate}}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="identite" placeholder="{{'contact.enterIdentify' | translate}}"
                              formControlName="name"
                              [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                            >
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                              <div *ngIf="f.name.errors.required">Sender's name is required</div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="email">{{'contact.email' | translate}}</label>
                        <div class="col-sm-10">
                            <input type="email" class="form-control" id="email" placeholder="{{'contact.enterEmail' | translate}}" 
                              formControlName="email"
                              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                            >
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                              <div *ngIf="f.email.errors.required || f.email.invalid">Email is invalid</div>
                              <!-- <div *ngIf="f.email.invalid">Email is invalid</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2" for="email">{{'contact.phone' | translate}}</label>
                      <div class="col-sm-10">
                          <input type="text" class="form-control" id="phone" placeholder="{{'contact.enterPhone' | translate}}" 
                          formControlName="phone">
                      </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="object">{{'contact.title' | translate}}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="object" placeholder="{{'contact.enterTitle' | translate}}"
                              formControlName="title"
                              [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                            >
                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                              <div *ngIf="f.title.errors.required">Message title is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="comment">{{'contact.message' | translate}}</label>
                        <div class="col-sm-10">
                            <textarea class="form-control" rows="5" id="comment" formControlName="message"
                              [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                            </textarea>
                            
                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                              <div *ngIf="f.message.errors.required">Message is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-offset-2 col-sm-10">
                            <button type="submit" class="btn btn-default">{{'contact.send' | translate}}</button>
                            
                        </div>
                    </div>
                </div>
              </form>

              <!-- <button class="btn btn-danger" (click)="testToastr()">test</button> -->
            </div>
        </div>
    </div>
</section>

