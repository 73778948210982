import { Component, OnInit } from '@angular/core';
import { TranslationLoaderService } from '../service/translation-loader.service';
import { locale as english } from '../shared/i18n/en';
import { locale as french } from '../shared/i18n/fr';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
  skills:any={
    technologies:[
      {name:"Java, C#, Spring Boot, ASP.NET Core",percent:80,remark:'excellent'}, 
      {name:"Node JS, Express",percent:80,remark:'excellent'},
      {name:"JavaScript, Angular", percent:80,remark:'excellent'},
      {name:"SQL Server, MySQL, pgSQL",percent:90,remark:'excellent'},
      {name:"SSIS, SSAS, MDX, DAX, PowerBI",percent:80,remark:'excellent'}, 
    ],
    services:[
      {name:"AWS - Lambda, API GW, Amplify, Kinesis",percent:70,remark:'very-good'},
      {name:"MongoDB, DynamoDB",percent:70,remark:'very-good'},
      {name:"Redis, RabbitMQ, SQS, nginx",percent:70,remark:'very-good'},
      {name:"Docker, Kubernetes",percent:60,remark:'average'},
      {name:"MacOS, Windows, Linux",percent:70,remark:'very-good'},
    ],
    methodologies:[
      {name:"E2E Solution and Architecture Design",percent:80,remark:'excellent'},
      {name:"Performance Optimization & Automation",percent:80,remark:'excellent'},
      {name:"Business Intelligence",percent:90,remark:'excellent'},
      {name:"Soft skills & Communication",percent:90,remark:'excellent'},
    ]
  };

  constructor(private _translationLoaderService: TranslationLoaderService) {
    this._translationLoaderService.loadTranslations(english, french);
  }

  ngOnInit(): void {
  }

}

    
    
  
