import { Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslationLoaderService } from '../service/translation-loader.service';
import { locale as english } from '../shared/i18n/en';
import { locale as french } from '../shared/i18n/fr';
import { IMessage } from '../models/interfaces';
import { DataService } from '../data.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  submitted: boolean = false;
  
  constructor(private _translationLoaderService: TranslationLoaderService, private formBuilder: FormBuilder, private dataService: DataService, private toastr: ToastrService) {
    this._translationLoaderService.loadTranslations(english, french);
    
  }
  get name() { return this.form.get('name'); }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  form = this.formBuilder.group({
     
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    phone: [''],
    title: ['', Validators.required],
    message: ['', Validators.required]
     
  });

  ngOnInit(): void {
   
  }

  addMessage(){
    this.submitted = true;

    if (this.form.invalid) {
      console.log(`invalid data`);
      return;
    }

    console.log(JSON.stringify(this.form.value));

    const messageObj : IMessage = this.form.value as IMessage;
    this.dataService.postMessage(messageObj).subscribe(res => {
      console.log('inside angular component');
      console.log(res);
      if(res.statusCode == 200){
        this.submitted = false;
        this.toastr.success('Message sent Successfully!', 'Sent');
        this.form.reset();
      }
      else{
        this.toastr.error('Message sending failed!');
      }
    })
    
  }

  testToastr(){
    console.log(this.toastr);
    
    this.toastr.success('Hello world!', 'Toastr fun!');
    
  }
}
